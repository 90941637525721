/* ////////////////////    FONT FAMILY / GENERAL    ////////////////////////// */
.inter-normal {
  font-family: 'Inter';
  font-weight: normal;
}

.inter-semibold {
  font-family: 'Inter';
  font-weight: 600;
}

.inter-bold {
  font-family: 'Inter';
  font-weight: bold;
}

.poppins-normal {
  font-family: 'Poppins';
  font-weight: normal;
}

.poppins-semibold {
  font-family: 'Poppins';
  font-weight: 600;
}

.poppins-bold {
  font-family: 'Poppins';
  font-weight: bold;
}

* {
  /* font-family: 'Inter'; */
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


p {
  line-height: 1.0;
  margin: 0;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100% !important;
}


.txt_truncate2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  /* min-height: 2.86em; */
}

.txt_truncate1 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.txt-truncate {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


/* ////////////////////    COLOR    ////////////////////////// */

.theme-color {
  color: #AF3535;
}

.theme-bg {
  background-color: #AF3535;
}

.gold-bg{
  background-color: #F5CB7B;
}

.gold-color{
  color: #F5CB7B;
}

.blue-color {
  color: #617FB8;
}

.blue-bg {
  background-color: #617FB8;
}

.cl-dark {
  color: #232325
}

.cl-label {
  color: #686868
}

/* ///////// Hover Effect ////////// */
.translateY {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateY:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateX {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateX:hover {
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* ////////////////////    SHAPE    ////////////////////////// */

.cut-edge-bottom {
  background:
    -o-radial-gradient(bottom left, circle 15px, #0000 98%, #fff) bottom left,
    -o-radial-gradient(bottom right, circle 15px, #0000 98%, #fff) bottom right;
  background:
    -o-linear-gradient(30deg, transparent 1rem, #fff 0) bottom left,
    -o-linear-gradient(150deg, transparent 1rem, #fff 0) bottom right;
  background:
    linear-gradient(60deg, transparent 1rem, #fff 0) bottom left,
    linear-gradient(-60deg, transparent 1rem, #fff 0) bottom right;
  background-size: 51% 100%;
  background-repeat: no-repeat;
}

/* ////////////////////    DIVIDER    ////////////////////////// */

.divider-dark {
  border-radius: 48px;
  background: #05122D;
  height: 4px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(153, 249, 255, 0.14), 0px 1px 2px 0px #000 inset;
  box-shadow: 0px 1px 2px 0px rgba(153, 249, 255, 0.14), 0px 1px 2px 0px #000 inset;
}

.divider-white {
  border-radius: 48px;
  background: #ffffff12;
  height: 4px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 2px 0px rgb(153 249 255 / 15%), 0px 2px 2px 0px #00000087 inset;
  box-shadow: 0px 1px 2px 0px rgb(153 249 255 / 15%), 0px 2px 2px 0px #00000087 inset;
}

/* ////////////////////    BOX    ////////////////////////// */
.glass-box-hightlight {
  background: -o-linear-gradient(299deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 67%, rgba(255, 255, 255, 0.64) 100%);
  background: linear-gradient(151deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 67%, rgba(255, 255, 255, 0.64) 100%);
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 4px;
  padding: 2px;
  border-radius: 10px;
}

.glass-box {
  border-radius: 10px;
  background: -o-linear-gradient(39deg, rgba(255, 255, 255, 0.41) 0.66%, rgb(255 255 255 / 23%) 30%, rgb(255 255 255 / 15%) 70%, rgba(241, 241, 241, 0.35) 100%);
  background: linear-gradient(51deg, rgba(255, 255, 255, 0.41) 0.66%, rgb(255 255 255 / 23%) 30%, rgb(255 255 255 / 15%) 70%, rgba(241, 241, 241, 0.35) 100%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 20px;
}

.glass-shadow {
  border-radius: 322.5px;
  opacity: 0.7;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.24) 29.1%, rgba(255, 255, 255, 0.50) 46.6%, rgba(255, 255, 255, 0.24) 64.6%, rgba(255, 255, 255, 0.00) 100.01%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.00)), color-stop(29.1%, rgba(255, 255, 255, 0.24)), color-stop(46.6%, rgba(255, 255, 255, 0.50)), color-stop(64.6%, rgba(255, 255, 255, 0.24)), color-stop(100.01%, rgba(255, 255, 255, 0.00)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.24) 29.1%, rgba(255, 255, 255, 0.50) 46.6%, rgba(255, 255, 255, 0.24) 64.6%, rgba(255, 255, 255, 0.00) 100.01%);
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: 322px;
  max-width: 80%;
  margin: 0 auto;
  height: 10px;
}


/* ///////////////  BOX SHADOW   ////////////////// */



/* ///////////////  gradients / Bkg   ////////////////// */


.gradient-theme {
  background: rgb(98, 206, 233);
  background: -o-linear-gradient(348deg, #62CEE9 0%, #8C52FF 96.56%);
  background: linear-gradient(102deg, #62CEE9 0%, #8C52FF 96.56%);
}

.gradient-main {
  background: rgb(203, 108, 230);
  background: -o-linear-gradient(339deg, #CB6CE6 -1.03%, #8C52FF 116.11%);
  background: linear-gradient(111deg, #CB6CE6 -1.03%, #8C52FF 116.11%);
}

.gradient-secondary {
  background: rgb(92, 225, 230);
  background: -o-linear-gradient(350deg, #5CE1E6 2.4%, #308BCC 91.46%);
  background: linear-gradient(100deg, #5CE1E6 2.4%, #308BCC 91.46%);
}

.gradient-red {
  background: #9E1616;
  background: -o-linear-gradient(16deg, #9E1616 2.35%, #A01919 6.16%, #B02A2C 35.69%, #B93437 65.22%, #BD383B 97.61%);
  background: linear-gradient(74deg, #9E1616 2.35%, #A01919 6.16%, #B02A2C 35.69%, #B93437 65.22%, #BD383B 97.61%);
}


/* ///////////  EMPTY STATE  ////////////////// */
#product_images img {
  max-width: 100% !important;
}

/* ///////////  SWIPER  ////////////////// */

.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev:after,
.swiper-button-next:after {
  color: white !important;
  font-size: medium !important;
  width: 6px;
  height: 10px;
  /* margin-top: -15px; */
  font-size: 10px;
  top: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 20px
}

.swiper-button-prev {
  margin-left: -10px;
}

.swiper-button-next {
  margin-right: -10px;
}

/* ///////////  AWSSLD  ////////////////// */
.awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.8);
}

.awssld__content {
  background-color: transparent;
}

.shop .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.215);
}

.wallet .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 1);
}

.wallet .awssld__content div {
  width: 100%;
  padding: 0 20px
}

.wallet .awssld__bullets {
  bottom: 0;
}

.wallet .awssld__bullets .awssld__bullets--active {
  background: #EEBE7F;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}

.wallet .awssld__bullets button {
  background: #e0e0e0;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}

.wallet .awssld__controls__arrow-right {
  right: 50px
}

.wallet .awssld__controls__arrow-left {
  left: 50px
}

.wallet .awssld__controls button {
  top: calc(50% - (0.8 * var(--control-button-height)));
  scale: 0.3;
}

.awssld__controls button .awssld__controls__arrow-left,
.awssld__controls button .awssld__controls__arrow-right {
  opacity: 1;
}

.awssld {
  --organic-arrow-color: #fff;
}


.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.orgchart-container {
  border: 2px dashed #d9d9d9;
  margin: 10px 0 0;
}

.slick-prev:before,
.slick-next:before {
  color: transparent;
}

.image-gallery-content.fullscreen {
  top: 20vh;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}



/* ////////////////////    marquee    ////////////////////////// */
.marquee {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fdc432;
  color: #333;
  border-bottom: 1px solid #e4e4e4;
  -webkit-box-shadow: inset -1px 3px 6px 0 #00000070;
  box-shadow: inset -1px 3px 6px 0 #00000070;
  border-radius: 0 0 15px 15px;
}

.marquee .marquee_content {
  /* position: absolute; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  line-height: 50px;
  -webkit-transform: translateX(450px);
  -ms-transform: translateX(450px);
  transform: translateX(450px);
  -webkit-animation: scroll-left 100s linear infinite;
  animation: scroll-left 100s linear infinite;
}

.marquee_content p {
  text-align: center;
  padding-right: 15px;
  padding-top: 1px;
  margin: 0;
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(450px);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -webkit-transform: translateX(450px);
    transform: translateX(450px);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


/* ////////////////////    PROGRESS BAR    ////////////////////////// */

.progressbar-bkg {
  height: 20px;
  border-radius: 48px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 4px 0px inset, rgba(208, 255, 252, 0.13) 2px 2px 2px 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 4px 0px inset, rgba(208, 255, 252, 0.13) 2px 2px 2px 0px;
}

.progressbar {
  border-radius: 48px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.17) -1px -1px 4px 0px inset, rgba(255, 255, 255, 0.61) 3px 3px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.17) -1px -1px 4px 0px inset, rgba(255, 255, 255, 0.61) 3px 3px 4px 0px inset;
  height: 20px;
}

.progress {
  height: 2.5em;
  width: 100%;
  background-color: #D8CDAD;
  -webkit-box-shadow: inset 3px 3px 6px #00000040, 3px 3px 6px #00000029;
  box-shadow: inset 3px 3px 6px #00000040, 3px 3px 6px #00000029;
  border: 1px solid #EBE5D2;
  border-radius: 10px;
  position: relative;
}

.progress:before {
  content: attr(data-label);
  position: absolute;
  text-align: center;
  top: 5px;
  left: 0;
  right: 0;
  margin-top: 3px;
  border-radius: 10px !important;
}

.progress .value {
  background-color: #C3A66E;
  -webkit-box-shadow: inset 3px 3px 6px #00000040, 3px 3px 6px #00000029;
  box-shadow: inset 3px 3px 6px #00000040, 3px 3px 6px #00000029;
  border-radius: 10px;
  display: inline-block;
  height: 100%;
  z-index: 99999;
}

progress,
progress::-webkit-progress-value {
  width: 500px;
  border: 0;
  height: 17px;
  border-radius: 20px;
  background: -webkit-gradient(linear, left top, right top, from(#55D3FF), to(#684EFF));
  background: linear-gradient(90deg, #55D3FF, #684EFF);
  -webkit-box-shadow: inset 3px 3px 3px 0 #ffffff4d, inset -3px -2px 2px 0 #0004;
  box-shadow: inset 3px 3px 3px 0 #ffffff4d, inset -3px -2px 2px 0 #0004;
}

progress::-webkit-progress-bar {
  border: 0;
  height: 17px;
  border-radius: 20px;
  background: #070b1b;
  -webkit-box-shadow: 3px 3px 8px 0px #76d4e038, inset 17px 4px 6px 0 #000;
  box-shadow: 3px 3px 8px 0px #76d4e038, inset 17px 4px 6px 0 #000;
}

progress::-moz-progress-bar {
  border: 0;
  height: 17px;
  border-radius: 20px;
  background: linear-gradient(90deg, #55D3FF, #684EFF);
  box-shadow: inset 3px 3px 3px 0 #ffffff4d, inset -3px -2px 2px 0 #0004;
}

/* ///////////////   PUBLIC PAGE    ///////////////// */
.countdown-box {
  width: 10vw;
  max-width: 80px;
  height: 10vw;
  max-height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  border-radius: 2vw;
  background: rgba(255, 255, 255, 0.68);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  aspect-ratio: 1/1;
}

.countdown-box2 {
  /* min-height: 70px; */
  padding: 5px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  border-radius: 2vw;
  background: rgba(255, 255, 255, 0.68);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  aspect-ratio: 1/1;
}

.textfield {
  border-radius: 10px;
  background: #F7F7F7;
  -webkit-box-shadow: -4px 4px 4px 0px rgba(179, 117, 117, 0.14), 6px -6px 12px 0px #FFF;
  box-shadow: -4px 4px 4px 0px rgba(179, 117, 117, 0.14), 6px -6px 12px 0px #FFF;
  /* padding: 15px 20px; */
  color: #6A6A6A;
}

.btn-float {
  background-color: #AF3535;
  color: #F5CB7B;
  border-radius: 48px;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 4;
  position: fixed;
}

.btn-square,
.btn-square-small {
  -webkit-box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.20) inset;
  box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.20) inset;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.09));
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.09));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-square {
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: #F5CB7B;
  border-radius: 10px;
}

.btn-square-small {
  width: 30px;
  height: 30px;
  font-size: 18px;
  color: #F5CB7B;
  border-radius: 6px;
}

.door-design-l,
.door-design-r {
  background-size: cover;
  background-repeat: no-repeat;
}

.door-design-r {
  background-position: right bottom;
}

.door-design-l {
  background-position: left bottom;
}

.left-door,
.right-door {
  height: 100vh;
  width: 50%;
  /* background: #4b0b0b; */
  background: #FFE9E9;
  margin: 0;
}

.left-door-open {
  -webkit-animation-name: left-door-open;
  animation-name: left-door-open;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}

.right-door-open {
  -webkit-animation-name: right-door-open;
  animation-name: right-door-open;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}

@-webkit-keyframes left-door-open {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -100%;
  }
}

@keyframes left-door-open {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -100%;
  }
}

@-webkit-keyframes right-door-open {
  from {
    margin-right: 0;
  }

  to {
    margin-right: -100%;
  }
}

@keyframes right-door-open {
  from {
    margin-right: 0;
  }

  to {
    margin-right: -100%;
  }
}

.lattern-animation {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation: lattern 5s infinite;
  animation: lattern 5s infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.spin-animation {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: spin 5s linear 0s infinite normal;
  animation: spin 5s linear 0s infinite normal;
}

.slide-up-open {
  -webkit-animation-name: slideup;
  animation-name: slideup;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.pop-in{
  -webkit-animation-name: popin;
  animation-name: popin;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;

}

.pop-out{
  -webkit-animation-name: popout;
  animation-name: popout;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;

}


@-webkit-keyframes popin {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes popin {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes popout {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes popout {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}


@-webkit-keyframes slideup {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
  }
}

@keyframes slideup {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
  }
}


@-webkit-keyframes lattern {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  75% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }

  99% {
    -webkit-transform: rotate(-0.32deg);
    transform: rotate(-0.32deg);
  }
}

@keyframes lattern {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  75% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }

  99% {
    -webkit-transform: rotate(-0.32deg);
    transform: rotate(-0.32deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.pos-story {
  padding-bottom: 120px;
  width: 100%;
}

/* ////////////////////    MEDIA SCREEN    ////////////////////////// */
@media only screen and (max-width: 600px) {
  /* .shop img {
    height: 89px;
  }

  .shop .awssld__container {
    padding-bottom: calc(var(--slider-height-percentage) * 0.41);
  }

  .wallet .awssld__container {
    padding-bottom: calc(var(--slider-height-percentage) * 1.2);
  }

  .wallet .awssld__bullets {
    bottom: 8px;
  } */

  .countdown-box {
    width: 50px;
    height: 50px;
    font-size: 21px;
    border-radius: 10px;
  }

  .flex-responsive {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pos-story {
    padding-bottom: 0;
    margin-top: -130px;
    width: 100%;
  }

}

@media only screen and (min-width: 601px) {
  .smUp-max50 {
    max-width: 50%;
  }
}

@media (max-width: 900px) {
  .flex-responsive-md {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 959.95px) {
  .wallet-card {
    height: 163px;
  }
}